import React from 'react'
import "../assets/css/data-hero.css"
const DataHero = () => {
  return (
    <div className="data-hero-container">
        <h1 className="dhc-title">Data Protection</h1>
    </div>
  )
}

export default DataHero